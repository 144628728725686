import React from 'react'
import Banner from '../Components/Banner'
import banner from "../Assets/Images/new_disel engine.jpg"
import bannerMob from "../Assets/Images/DISEL GENERATORs.jpg"
import Heading from '../Components/Heading'
import ProductCard from '../Components/CardsComponents/ProductCard'
import img1 from "../Assets/Images/diesel engine/generator set/image039.jpg"
import img2 from "../Assets/Images/diesel engine/generator set/image021.jpg"
import genset from "../Assets/Images/genset.png"
import "../index.css"
import { Button, Col, Container, Row } from 'react-bootstrap'
const GeneratorSet = () => {
  window.scrollTo(0, 0);

  return (
    <>
      {/* {
        window.innerWidth > "576" ?
          <Banner banner={banner} />
          :
          <Banner banner={bannerMob} />
      }
      <Heading Headings={"GENERATOR SET"} className={"text-center"} />
      <ProductCard data={data} ProductClass={"dieselProduct"} /> */}
      <div className='p-md-4 py-4 px-2' style={{ background: "#eff0f0" }}>
        <Heading Headings={"Generator Drive Engines"} className={"mx-md-5 mx-sm-0"} />
      </div>
      <Container fluid>
        <Row >
          {
            window.innerWidth < 576 &&
            <Col lg={7} md={7} sm={6} className='p-0'>
              <img src={genset} style={{ height: window.innerWidth < 576 ? "200px" : "" }} className='img-fluid' alt="genset" />
            </Col>
          }
          <Col lg={5} md={5} sm={6} className='marineSection d-flex flex-column align-items-center justify-content-center'>
            <div className='mx-md-5 my-3'>
              <h2 className='text-white'>
                Prime or Standby Power
              </h2>
              <p className=''>
                John Deere generator drive engines are ready when and where you need them. They provide fast response for standby situations and exceptional load recovery in a wide variety of applications.
              </p>
              <Button className='p-2 ' variant="outline-warning"> <a className='text-white' href="https://www.deere.com/en/generator-drive-engines/" target='_blank'> See Our Full Lineup </a></Button>
            </div>
          </Col>
          {
            window.innerWidth > 576 &&
            <Col lg={7} md={7} sm={6} className='p-0'>
              <img src={genset} style={{ height: window.innerWidth < 576 ? "" : "" }} className='img-fluid' alt="genset" />
            </Col>
          }
        </Row>
      </Container >
    </>
  )
}

export default GeneratorSet

const data = [
  {
    imgs: img1,
    title: "GENERATOR SET",
    Model: "409"
  },
  {
    imgs: img2,
    title: "GENERATOR SET",
    Model: "420"
  },
]