import React from 'react'
import Banner from '../Components/Banner'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { MdLocationOn } from "react-icons/md";
import { ImMobile } from "react-icons/im";
import { HiOutlineMail } from "react-icons/hi";
import banner from "../Assets/Images/CONTACT USS.jpg"
import bannerMob from "../Assets/Images/contact us.png"
const Contact = () => {
  window.scrollTo(0, 0);
  return (
    <>
      {
        window.innerWidth > "576" ?
          <Banner banner={banner} />
          :
          <Banner banner={bannerMob} />
      }
      <Card className='p-3 m-3'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29996.11764075024!2d73.74507432101757!3d19.9869012604932!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddec9d50000001%3A0x6bfc86902ddfcc70!2sArch%20Enterprises!5e0!3m2!1sen!2sin!4v1700644018473!5m2!1sen!2sin" width="100%" height="600" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        <Card className='footerContact shadow-lg my-3'>
          <Card.Body className='text-dark p-3'>
            <Card.Header className='p-3 border-0 footerCardHeader'>ADDRESS</Card.Header>
            <Row className='p-2'>
              <Col lg={1} md={1} sm={2} xs={1} className='p-1'>
                <MdLocationOn style={{ color: '#fd7a01', fontSize:"1.2rem" }}/>
              </Col>
              <Col lg={11} md={11} sm={10} xs={11} className='p-1'>
                {/* <Card.Text className='text-black'> */}
                  <a className='text-black' target="_blank" href="https://maps.app.goo.gl/wWukSwFEtH9PgXwx5">Plot No.49 Prathmesh, Khutwad Nagar, Nashik, Maharashtra, India</a>
                {/* </Card.Text> */}
              </Col>
              <Col lg={1} md={1} sm={2} xs={1} className='p-1'>
                <ImMobile style={{ color: '#fd7a01', fontSize:"1.2rem" }}/>
              </Col>
              <Col lg={11} md={11} sm={10} xs={11} className='p-1'>
                {/* <Card.Text className='text-dark'> */}
                  <a href="tel:+918390704453" style={{ color: 'black' }}>+91 8390704453 / </a>
                  <a href="tel:+918956155816" style={{ color: 'black' }}>+91 8956155816</a>
                {/* </Card.Text> */}
              </Col>
              <Col lg={1} md={1} sm={2} xs={1} className='p-1'>
                <HiOutlineMail style={{ color: '#fd7a01', fontSize:"1.2rem" }}/>
              </Col>
              <Col lg={11} md={11} sm={10} xs={11} className='p-1'>
                {/* <Card.Text className='text-dark px-3'> */}
                  <a href="mailto:contact@trikfire.com" style={{ color: 'black' }}>contact@trikfire.com</a>
                {/* </Card.Text> */}
              </Col>
              <Col lg={1} md={1} sm={2} xs={1} className='p-1'>
                <HiOutlineMail style={{ color: '#fd7a01', fontSize:"1.2rem" }}/>
              </Col>
              <Col lg={11} md={11} sm={10} xs={11} className='p-1'>
                {/* <Card.Text className='text-dark px-3'> */}
                  <a href="mailto:sales@trikfire.com" style={{ color: 'black' }}>sales@trikfire.com</a>
                {/* </Card.Text> */}
              </Col>
              <Col lg={1} md={1} sm={2} xs={1} className='p-1'>
                <HiOutlineMail style={{ color: '#fd7a01', fontSize:"1.2rem" }}/>
              </Col>
              <Col lg={11} md={11} sm={10} xs={11} className='p-1'>
                {/* <Card.Text className='text-dark px-3'> */}
                  <a href="mailto:prasad@trikfire.com" style={{ color: 'black' }}>prasad@trikfire.com</a>
                {/* </Card.Text> */}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Card>
    </>
  )
}

export default Contact