import React from 'react'
import { Carousel } from 'react-bootstrap'

const MainSlider = ({ data }) => {
  return (
    <>
      <Carousel interval={2000}>
        {
          data.map((item, id) => {
            return (
              <Carousel.Item>
                <img src={item} alt={item} className='img-fluid'/>
              </Carousel.Item>
            )
          })
        }
      </Carousel>
    </>
  )
}

export default MainSlider