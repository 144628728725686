import React from "react";
import { Banner1, Banner2 } from "../Components/Banner";
import AboutComp from "../PageComponents/HomeComp/AboutComp";
import img1 from "../Assets/Images/1.png";
import img2 from "../Assets/Images/2.png";
import img3 from "../Assets/Images/3.png";
import clientImg from "../Assets/Images/clientImg.png";
import engine from "../Assets/Images/generator.png";
import marine from "../Assets/Images/inddustrial.png";
import fire from "../Assets/Images/water.png";
import CardComponent1 from "../Components/CardsComponents/CardComponent1";
import CardComponent2 from "../Components/CardsComponents/CardComponent2";
import CardComponent3 from "../Components/CardsComponents/CardComponent3";
import CardComponent4 from "../Components/CardsComponents/CardComponent4";
import clientLogo1 from "../Assets/Images/fedex.png";
import MainSlider from "../Components/MainSlider";
import MobSlider1 from "../Assets/Images/mobile home page banner 3.jpg"
import MobSlider2 from "../Assets/Images/slider2Mob.png"
import MobSlider3 from "../Assets/Images/marinemob1.jpg"
import MobSlider4 from "../Assets/Images/mob4.jpg"
import LargeSlider1 from "../Assets/Images/web home page banner 3.jpg"
import LargeSlider2 from "../Assets/Images/slider2.jpg"
import LargeSlider3 from "../Assets/Images/marineweb.jpg"
import LargeSlider4 from "../Assets/Images/web4.jpg"
import Temp from "./Temp";
const LargeSlider = [LargeSlider1, LargeSlider2, LargeSlider3, LargeSlider4]
const MobSliderImgs = [MobSlider1, MobSlider2, MobSlider3, MobSlider4];
const productData = [
  {
    images: img1,
    title: "JOHN DEERE MARINE ENGINE",
    model: "MODEL 6135",
    buttonName: "Learn More",
  },
  {
    images: img2,
    title: "JOHN DEERE MARINE ENGINE",
    model: "MODEL 6135",
    buttonName: "Learn More",
  },
  {
    images: img3,
    title: "JOHN DEERE MARINE ENGINE",
    model: "MODEL 6135",
    buttonName: "Learn More",
  },
  {
    images: img3,
    title: "JOHN DEERE MARINE ENGINE",
    model: "MODEL 6135",
    buttonName: "Learn More",
  },
  {
    images: img3,
    title: "JOHN DEERE MARINE ENGINE",
    model: "MODEL 6135",
    buttonName: "Learn More",
  },
  {
    images: img3,
    title: "JOHN DEERE MARINE ENGINE",
    model: "MODEL 6135",
    buttonName: "Learn More",
  },
];
const clientData = [
  {
    images: clientImg,
    title: "Sakshi P.",
    description: "TrikFire's expertise & dedication keep our industrial engines running smoothly. They are our trusted partner for maintenance, repairs, & hard-to-find parts.",
  },
  {
    images: clientImg,
    title: "Pratiksha T.",
    description: "TrikFire's quick installation & expertise saved our operation from a power outage. Their professionalism & reliable generators kept us running.",
  },
  {
    images: clientImg,
    title: "Aditya M.",
    description: "TrikFire's marine-specific services ensure the safe & efficient operation of my vessel at sea. They're the only team I trust with my engines.",
  },
  {
    images: clientImg,
    title: "Rajesh A.",
    description: "TrikFire offers a complete fire & safety solution, from high-quality products to expert training. They're our reliable partner for peace of mind.",
  },
  {
    images: clientImg,
    title: "Pranav D.",
    description: "TrikFire's commitment to the highest standards protects my business from potential fire hazards. Their responsive service & expert advice are invaluable.",
  },
];
const clientLogo = [
  {
    imgs: clientLogo1,
  },
  {
    imgs: clientLogo1,
  },
  {
    imgs: clientLogo1,
  },
  {
    imgs: clientLogo1,
  },
];
const imgCards = [
  {
    imgs: engine,
    links: "/john-deere/diesel-engine/generator-set",
  },
  {
    imgs: fire,
    links: "/john-deere/diesel-engine/marine",
  },
  {
    imgs: marine,
    links: "/john-deere/diesel-engine/industrial",
  },
];
const Home = () => {
  window.scrollTo(0, 0);
  return (
    <>
      <MainSlider data={window.innerWidth < 576 ? MobSliderImgs : LargeSlider} />
      {/* <Banner banner={banner} /> */}
      <AboutComp />
      <Banner1 />
      {/* <CardComponent1 data={productData} Headings={"Our Products"} /> */}
      {/* <Temp data={productData}/> */}
      {/* <CardComponent2 data={clientData} Headings={"Reviews"} /> */}
      <CardComponent3 data={imgCards} Headings={"JOhn Deere Products"} />
      <Banner2 />
      {/* <CardComponent4 Headings={"Our Clients"} data={clientLogo} /> */}
    </>
  );
};

export default Home;
