import React from 'react'
import Banner from '../Components/Banner'
import banner from "../Assets/Images/new_industrail.jpg"
import bannerMob from "../Assets/Images/industrialMob.jpg"
import Heading from '../Components/Heading'
import ProductCard from '../Components/CardsComponents/ProductCard'
import img1 from "../Assets/Images/diesel engine/industrail/image1.jpg"
import img2 from "../Assets/Images/diesel engine/industrail/image2.jpg"
import img3 from "../Assets/Images/diesel engine/industrail/image3.jpg"
import img4 from "../Assets/Images/diesel engine/industrail/image4.jpg"
import img5 from "../Assets/Images/diesel engine/industrail/image5.jpg"
import img6 from "../Assets/Images/diesel engine/industrail/image6.jpg"
import img7 from "../Assets/Images/diesel engine/industrail/image7.jpg"
import img8 from "../Assets/Images/diesel engine/industrail/image8.jpg"
import img9 from "../Assets/Images/diesel engine/industrail/image9.jpg"
import img10 from "../Assets/Images/diesel engine/industrail/image10.jpg"
import img11 from "../Assets/Images/diesel engine/industrail/image11.jpg"
import img12 from "../Assets/Images/diesel engine/industrail/image12.jpg"
import img13 from "../Assets/Images/diesel engine/industrail/image13.jpg"
import industrialMachine from "../Assets/Images/industrial.jpg"
import "../index.css"
import { Button, Col, Container, Row } from 'react-bootstrap'
const IndustrialSet = () => {
    window.scrollTo(0, 0);
    return (
        <>
            {/* {
                window.innerWidth > "576" ?
                    <Banner banner={banner} />
                    :
                    <Banner banner={bannerMob} />
            }
            <Heading Headings={"INDUSTRIAL SET"} className={"text-center"} />
            <ProductCard data={data} ProductClass={"dieselProduct"} /> */}

            <>
                <div className='p-md-4 py-4 px-2' style={{ background: "#eff0f0" }}>
                    <Heading Headings={"Industrial Diesel Engines"} className={"mx-md-5"} />
                </div>
                <Container fluid>
                    <Row >
                        {
                            window.innerWidth < 576 &&
                            <Col lg={7} md={7} sm={6} className='p-0'>
                                <img src={industrialMachine} style={{ height: window.innerWidth < 576 ? "200px" : "" }} className='img-fluid' alt="marineImg" />
                            </Col>
                        }
                        <Col lg={5} md={5} sm={6} className='marineSection d-flex flex-column align-items-center justify-content-center'>
                            <div className='mx-md-5 my-3'>
                                <h2 className='text-white'>
                                    Durable, Reliable Power Solutions
                                </h2>
                                <p className=''>
                                    With power ratings from 36 to 677 kW (48 to 908 hp) and emissions levels from Final Tier 4/Stage V to non-certified, John Deere helps OEMs meet power needs in all areas of the world.
                                </p>
                                <Button className='p-2 ' variant="outline-warning"> <a className='text-white' href="https://www.deere.com/en/industrial-engines/" target='_blank'> See Our Full Lineup </a></Button>
                            </div>
                        </Col>
                        {
                            window.innerWidth > 576 &&
                            <Col lg={7} md={7} sm={6} className='p-0'>
                                <img src={industrialMachine} className='img-fluid' alt="marineImg" />
                            </Col>
                        }
                    </Row>
                </Container >

            </>
        </>
    )
}

export default IndustrialSet

const data = [
    {
        imgs: img1,
        title: "INDUSTRIAL SET",
        Model: "409"
    },
    {
        imgs: img2,
        title: "INDUSTRIAL SET",
        Model: "420"
    },
    {
        imgs: img3,
        title: "INDUSTRIAL SET",
        Model: "409"
    },
    {
        imgs: img6,
        title: "INDUSTRIAL SET",
        Model: "409"
    },
    {
        imgs: img8,
        title: "INDUSTRIAL SET",
        Model: "420"
    },
    {
        imgs: img9,
        title: "INDUSTRIAL SET",
        Model: "409"
    },
    {
        imgs: img10,
        title: "INDUSTRIAL SET",
        Model: "409"
    },
    {
        imgs: img11,
        title: "INDUSTRIAL SET",
        Model: "420"
    },
    {
        imgs: img12,
        title: "INDUSTRIAL SET",
        Model: "409"
    },
    {
        imgs: img13,
        title: "INDUSTRIAL SET",
        Model: "409"
    },
]