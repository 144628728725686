import { useEffect } from "react";
import "./App.css";
import wappChat from "./Assets/Images/wapImg.gif"
import Routing from "./Routing";
import "aos/dist/aos.css";
import Aos from "aos";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
function App() {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);
  window.scrollTo(0, 0);
  return (
    <>
      <Routing />
      <div className="">
        <div className="d-flex justify-content-end">
          <a href="https://wa.me/+918390704453?text=Hello How Can I Help You ?" target="_blank">
            <img src={wappChat} alt="Whatsapp" className="m-4 wappIcons" style={{ zIndex: "1", width: "5%", position: "fixed", bottom: "1rem", right: "0" }} />
          </a>
        </div>
      </div >
    </>
  );
}

export default App;
