import React from 'react';
import Heading from '../Heading';
import { Col, Container, Row, Card, Button } from 'react-bootstrap';
import '../../index.css';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router-dom';

const CardComponent3 = ({ Headings, data }) => {
    // const swiperParams = {
    //     spaceBetween: 30,
    //     slidesPerView: 3,
    //     navigation: {
    //         nextEl: '.swiper-button-next', // Add appropriate class or selector
    //         prevEl: '.swiper-button-prev', // Add appropriate class or selector
    //     },
    //     pagination: {
    //         el: '.swiper-pagination',
    //         clickable: true,
    //     },
    //     onSwiper: (swiper) => console.log(swiper),
    //     breakpoints: {
    //         0: {
    //             slidesPerView: 1, // Show one card for mobile devices (width < 576px)
    //         },
    //         576: {
    //             slidesPerView: 2, // Show two cards for tablets (width >= 576px)
    //         },
    //         992: {
    //             slidesPerView: 3, // Show three cards for larger screens (width >= 992px)
    //         },
    //     },
    // };
    return (
        <>
            <Container fluid>
                {
                    Headings &&
                    <Heading Headings={Headings} />
                }
                <Row className='my-2'>
                    {/* <Swiper key="swiper1"
                        {...swiperParams}
                    > */}
                        {
                            data?.map((item, id) => {
                                return (
                                    <>

                                        <Col key={id} lg={4} sm={12} md={4}>
                                            {/* <SwiperSlide> */}
                                                <div>
                                                    <Link to={item.links}>
                                                        <Card className='d-flex flex-row align-items-center px-3 my-3 border-0' data-aos="zoom-in-down">
                                                            <Card.Img className='cardFireImgs' variant="top" src={item.imgs} />
                                                        </Card>
                                                    </Link>
                                                </div>
                                                {/* <div style={{ aspectRatio: '1 / 1' }}>
                                            <Card.Img variant="top" src={item} style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
                                        </div> */}
                                            {/* </SwiperSlide> */}
                                        </Col>
                                    </>
                                )
                            })
                        }
                    {/* </Swiper> */}
                </Row>
            </Container>
        </>
    )
}

export default CardComponent3;