import React from "react";
import Slider from "react-slick";
import { Col, Container, Row, Card, Button } from 'react-bootstrap';

function Temp({ data }) {
    var settings = {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 3
    };

    return (
        <Slider {...settings}>
            {data.map((item, id) => (
                <Card key={id} className='d-flex flex-row align-items-center px-3 my-2 card2 reviewsCard'>
                    <Card.Img className='mx-auto my-3' style={{ width: "35%" }} variant="top" src={item?.images} />
                    <Card.Body>
                        <Card.Text style={{ textAlign: "justify" }} className='review_desc'>
                            {item?.description}
                        </Card.Text>
                        <Card.Title className='text-white'>{item?.title}</Card.Title>
                        {
                            item?.buttonName &&
                            <Button variant="primary">{item?.buttonName}</Button>
                        }
                    </Card.Body>
                </Card>
            ))}
        </Slider>
    );
}

export default Temp;
