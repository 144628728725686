import React from 'react'

const Heading = ({ Headings, className }) => {

    console.log("Headings", Headings);
    return (
        <>
            <h2 className={`text-uppercase mt-4 ${className}`}>
                {Headings}
            </h2>
        </>
    )
}

export default Heading