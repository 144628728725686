import React from 'react'
import Banner from '../Components/Banner'
import banner from "../Assets/Images/fps.jpg"
import bannerMob from "../Assets/Images/firePumpMob.png"
import Heading from '../Components/Heading'
import ProductCard from '../Components/CardsComponents/ProductCard'
import img1 from "../Assets/Images/Fire Pump set/1) Diesel Driven Fire Pump.jpg"
import img2 from "../Assets/Images/Fire Pump set/3) Jockey Pump.jpg"
import img3 from "../Assets/Images/Fire Pump set/2) Electrical Motor Driven Fire Pump.jpg"
import img4 from "../Assets/Images/Fire Pump set/3) Jockey Pump a.jpg"
const FPS = () => {
  window.scrollTo(0, 0);
  return (
    <>
      {
        window.innerWidth > "576" ?
          <Banner banner={banner} />
          :
          <Banner banner={bannerMob} />
      }
      <Heading Headings={"FIRE PUMP SET"} className={"text-center"} />
      <ProductCard data={data} ProductClass={"ProductCard"} />
    </>
  )
}

export default FPS

const data = [
  {
    imgs: img1,
    title: "Diesel Driven Fire Pump",
    Model: "409"
  },
  {
    imgs: img2,
    title: "Jockey Pump",
    Model: "420"
  },
  {
    imgs: img3,
    title: "Electric Motor Driven",
    Model: "409"
  },
  // {
  //   imgs: img4,
  //   title: "Jockey Pump",
  //   Model: "420"
  // },
]