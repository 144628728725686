import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import "../../index.css"
import { Link } from 'react-router-dom'
const ProductCard = ({ data, ProductClass }) => {
  let myPath = window.location.pathname
  let category = myPath?.slice(12, 25)
  const { pathname } = window.location;
  const isSpecialPath = pathname === '/products/fire-pump-set' || pathname === '/products/fire-fighting-accessories';
  return (
    <>
      <Container>
        <Row>
          {data.map((item, id) => {
            return (
              <Col lg={4}>
                {
                  !isSpecialPath ?
                    <a href={`${"https://www.deere.com/"}`} target='_blank'>
                      <Card className={`text-center ${ProductClass} my-3 `} data-aos="fade-down">
                        <Card.Img className='mx-auto productImgs' variant="top" src={item?.imgs} />
                        <Card.Footer className='productFooter'>
                          <Card.Body>
                            {
                              category === "diesel-engine" &&
                              <Card.Title className="fw-bold" as={"h4"}>{"DIESEL ENGINE"}</Card.Title>
                            }
                            <Card.Title className="productTitle" as={"h3"}>{item?.title}</Card.Title>
                            {/* <Card.Text className='text-black'>
                          <h4 className='d-inline modelDesc'>MODEL - <span className='fw-lighter modelId'>{item?.Model}</span></h4>
                        </Card.Text> */}
                          </Card.Body>
                        </Card.Footer>
                      </Card>
                    </a>
                    :
                    <Card className={`text-center ${ProductClass} my-3 `} data-aos="fade-down">
                      <Card.Img className='mx-auto productImgs' variant="top" src={item?.imgs} />
                      <Card.Footer className='productFooter'>
                        <Card.Body>
                          {
                            category === "diesel-engine" &&
                            <Card.Title className="fw-bold" as={"h4"}>{"DIESEL ENGINE"}</Card.Title>
                          }
                          <Card.Title className="productTitle" as={"h3"}>{item?.title}</Card.Title>
                          {/* <Card.Text className='text-black'>
                          <h4 className='d-inline modelDesc'>MODEL - <span className='fw-lighter modelId'>{item?.Model}</span></h4>
                        </Card.Text> */}
                        </Card.Body>
                      </Card.Footer>
                    </Card>
                }
              </Col>
            )
          })
          }
        </Row>
      </Container>
    </>
  )
}

export default ProductCard