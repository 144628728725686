import React from 'react'
import { Container, Row } from 'react-bootstrap'

import banner1 from "../Assets/Images/banner1.png"
import banner2 from "../Assets/Images/BANNER2.png"
import MobBanner2 from "../Assets/Images/MobBanner2.png"
let windowSize = window.innerWidth
const Banner = ({banner}) => {
    return (
        <>
            <Container fluid>
                <Row>
                    <img src={banner} className='m-0 p-0' alt="banner" />
                </Row>
            </Container>
        </>
    )
}

export default Banner

export const Banner1 = () => {
    return (
        <>
            <Container fluid>
                <Row>
                    <img src={windowSize < 576 ? MobBanner2 : banner1} className='m-0 p-0' alt="banner" />
                </Row>
            </Container>
        </>
    )
}

export const Banner2 = () => {
    return (
        <>
            <Container fluid>
                <Row>
                    <img src={banner2} className='m-0 p-0' alt="banner" />
                </Row>
            </Container>
        </>
    )
}
