import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import logo from "../Assets/Images/TRIFIRE LOGOWHITEE 1.png";
import { NavDropdown } from 'react-bootstrap';
import icon from "../Assets/Images/icons.png"
function Header() {
    const [isNavOpen, setIsNavOpen] = useState(false); // State to control the offcanvas menu
    const navigation = useNavigate();

    const closeNav = () => {
        setIsNavOpen(false);
    };

    const handleLinkClick = (url) => {
        navigation(url);
        closeNav();
    };

    const Menu = [
        {
            title: "Home",
            url: "/"
        },
        {
            title: "About",
            url: "/about"
        },
        {
            title: "Products",
            category: [
                {
                    subtitle: "FIRE PUMP SET",
                    url: "/products/fire-pump-set"
                },
                {
                    subtitle: "FIRE FIGHTING ACCESSORIES",
                    url: "/products/fire-fighting-accessories"
                },
            ]
        },
        {
            title: ["JOHN", " DEERE"],
            category: [
                {
                    subtitle: "GENERATOR SET",
                    url: "/john-deere/diesel-engine/generator-set"
                },
                {
                    subtitle: "MARINE",
                    url: "/john-deere/diesel-engine/marine"
                },
                {
                    subtitle: "INDUSTRIAL",
                    url: "/john-deere/diesel-engine/industrial"
                },

            ]
        },
        {
            title: "Services",
            url: "/services"
        },
        {
            title: "Contact",
            url: "/contact"
        },
    ];

    return (
        <>
            <Navbar expand="lg" className="bg-dark p-0  py-lg-2 px-2" expanded={isNavOpen} onToggle={() => setIsNavOpen(!isNavOpen)}>
                <Container className='align-items-end HeaderContainer p-0'>
                    <Navbar.Brand href="/">
                        <img src={logo} className="img-fluid logo" alt='logo' />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className={`p-0 ${window.innerWidth > 576 ? 'bg-dark' : 'bg-white'}`}>
                        <Nav className="justify-content-center my-3 ms-lg-5 ps-lg-5 flex-grow-1">
                            {Menu.map((item, id) => (
                                <React.Fragment key={id}>
                                    {!item.category ? (
                                        <Link
                                            className={`mx-3 fs-5 text-uppercase headertext hvr-underline-from-center`}
                                            to={item.url}
                                            onClick={() => handleLinkClick(item.url)}
                                        >
                                            {item.title}
                                        </Link>
                                    ) : (
                                        <NavDropdown
                                            title={item.title !== "Products" ? item.title.map((item, id) => (
                                                <>
                                                    {/* {
                                                        id == 0 &&
                                                        <img src={icon} alt="icon" className='img-fluid w-5' />
                                                    } */}
                                                    <span key={id} style={{ color: item === "JOHN" ? (window.innerWidth < 576 ? "black" : "white") : "yellow" }}>{item}</span>
                                                </>
                                            )) : item.title}
                                            id="basic-nav-dropdown"
                                            className={`${item.title === "Products" && window.innerWidth < 576 ? "text-black" : "menuText"} fs-5 mx-3 text-uppercase headertext`}
                                        >
                                            {item.category.map((unit, index) => (
                                                <NavDropdown.Item
                                                    key={index}
                                                    onClick={() => handleLinkClick(unit.url)}
                                                >
                                                    {unit?.subtitle}
                                                </NavDropdown.Item>
                                            ))}
                                        </NavDropdown>
                                    )}
                                </React.Fragment>
                            ))}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default Header;
