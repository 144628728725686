import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './Pages/Home'
import About from './Pages/About'
import Services from './Pages/Services'
import Contact from './Pages/Contact'
import Header from './Components/Header'
import Footer from './Components/Footer'
import FPS from './Pages/FPS'
import FFA from './Pages/FFA'
import MarineSet from './Pages/MarineSet'
import IndustrialSet from './Pages/IndustrialSet'
import GeneratorSet from './Pages/GeneratorSet'
import SamplePage from './samplePage'

const Routing = () => {
    
    return (
        <>
            <Header />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/about' element={<About />} />
                <Route path='/products/fire-pump-set' element={<FPS />} />
                <Route path='/products/fire-fighting-accessories' element={<FFA />} />
                <Route path='/john-deere/diesel-engine/marine' element={<MarineSet />} />
                <Route path='/john-deere/diesel-engine/industrial' element={<IndustrialSet />} />
                <Route path='/john-deere/diesel-engine/generator-set' element={<GeneratorSet />} />
                <Route path='/services' element={<Services />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/johndeere' element={<Contact />} />
                {/* <Route path='/test' element={<SamplePage />} /> */}
            </Routes>
            <Footer />
        </>
    )
}

export default Routing