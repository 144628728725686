// import React from 'react'
// import Banner from '../Components/Banner'
// import banner from "../Assets/Images/serviceBanner.jpg"
// import bannerMob from "../Assets/Images/serviceBannerMob.png"
// import { Card, Col, Container, Row } from 'react-bootstrap'

// const serviceData = [
//   {
//     services: "ROUTINE MAINTENANCE SERVICE",
//     subServices: [
//       "A - CHECK SERVICE", "B - CHECK SERVICE", "C - CHECK SERVICE", "D - CHECK SERVICE",
//     ]
//   },
//   {
//     services: "ENGINES OVERHAULING SERVICE",
//   },
//   {
//     services: "PUMP PERFORMANCE TESTING",
//   },
//   {
//     services: "COMPRESSION TESTING",
//   },
//   {
//     services: "FUEL INJECTION PUMP CALIBRATION SERVICES",
//   },
//   {
//     services: "ALL FIRE PUMP ACCESSORIES INSTALLATION TESTING AND MAINTENANCE SERVICE",
//   },
//   {
//     services: "TURNKEY PROJECTS INSTALLATIONS IN FIRE FIGHTING SYSTEMS",
//   },
// ]
// const Services = () => {
//   window.scrollTo(0, 0);
//   return (
//     <>
//       {
//         window.innerWidth > "576" ?
//           <Banner banner={banner} />
//           :
//           <Banner banner={bannerMob} />
//       }
//       <Container fluid>
//         <Row className='mt-4'>
//           {
//             serviceData.map((item, id) => {
//               return (
//                 <>
//                   {
//                     !item.subServices ?
//                       <Col lg={4}>
//                         <Card className='d-flex justify-content-center align-items-center p-3 m-3 text-center serviceCard' data-aos="fade-up">
//                           <Card.Header className='border-0 p-0' as={"h4"}>{item.services}</Card.Header>
//                         </Card>
//                       </Col>
//                       :
//                       <>
//                         <Col lg={4}>
//                           <Card className='d-flex justify-content-center align-items-center p-3 m-3 text-center serviceCard' data-aos="fade-up">
//                             <Card.Header className='border-0' as={"h4"}>{item.services}</Card.Header>
//                           </Card>
//                         </Col>
//                         <Col lg={8} md={8} sm={12}>
//                           <Row>
//                             {
//                               item.subServices.map((unit, index) => {
//                                 return (
//                                   <>
//                                     <Col lg={6} md={6} sm={12}>
//                                       <Card className='d-flex justify-content-center align-items-center p-1 m-1 text-center text-white subServiceCard' data-aos="fade-up">
//                                         <Card.Header className='border-0' as={"h4"}>{unit}</Card.Header>
//                                       </Card>
//                                     </Col>
//                                   </>
//                                 )
//                               })
//                             }
//                           </Row>
//                         </Col>

//                       </>
//                   }
//                 </>
//               )
//             })
//           }
//         </Row>
//       </Container>

//     </>
//   )
// }

// export default Services

import React from 'react'
import Banner from '../Components/Banner'
import banner from "../Assets/Images/serviceBanner.jpg"
import bannerMob from "../Assets/Images/serviceBannerMob.png"
import { Card, Col, Container, Row } from 'react-bootstrap'
import eng from '../Assets/Images/Ellipse.png'
// import eng1 from '../Assets/Images/Ellipse2.png'
import eng2 from '../Assets/Images/Ellipse3.png'
import eng3 from '../Assets/Images/4.png'
import eng4 from '../Assets/Images/6.png'
import eng5 from '../Assets/Images/5.png'
import eng1 from '../Assets/Images/02.png'
import eng6 from '../Assets/Images/03.png'
const serviceData = [
  {
    services: "ROUTINE MAINTENANCE SERVICE",
    subServices: [
      "A - CHECK SERVICE", "B - CHECK SERVICE", "C - CHECK SERVICE", "D - CHECK SERVICE",
    ]
  }]
const newdata = [
  {
    imgs: eng2,
    services: "ENGINES OVERHAULING SERVICE",
  },
  {
    imgs: eng1,
    services: "PUMP SERVICES AND PUMP PERFORMANCE TESTING",
  },
  {
    imgs: eng6,
    services: "COMPRESSION TESTING",
  },
  {
    imgs: eng3,
    services: "FUEL INJECTION PUMP CALIBRATION SERVICES",
  },
  {
    imgs: eng4,
    services: "ALL FIRE PUMP ACCESSORIES INSTALLATION TESTING AND MAINTENANCE SERVICE",
  },
  {
    imgs: eng5,
    services: "TURNKEY PROJECTS INSTALLATIONS IN FIRE FIGHTING SYSTEMS",
  },
]
const Services = () => {
  window.scrollTo(0, 0);
  return (
    <>
      {
        window.innerWidth > "576" ?
          <Banner banner={banner} />
          :
          <Banner banner={bannerMob} />
      }
      <Container >
        <Row className='mt-4' >
          {
            serviceData.map((item, id) => {
              return (
                <>
                  {
                    !item.subServices ?
                      <></>
                      // <Col lg={4}>

                      //   <Card className='d-flex justify-content-center align-items-center p-3 m-3 text-center serviceCard' data-aos="fade-up">
                      //     <Card.Header className='border-0 p-0' as={"h4"}>{item.services}</Card.Header>
                      //   </Card>
                      // </Col>

                      // <Col lg={4} className='eng-card  d-grid  justify-content-center  '>
                      //   <img src={item.imgs} alt="" className=' engs-im' />
                      //   <p style={{paddingLeft:"10rem"}} className=' text-black fw-bolder  text-center   '>{item.services}</p>
                      // </Col>

                      :
                      <>
                        <Col lg={{ span: 4, offset: 1 }} md= {5} className=' d-flex justify-content-center align-items-center '>

                          <img src={eng} className='eng-im' data-aos="fade-up" alt="" />

                          <Card className=' align-items-center p-3 m-3 border-0 serviceCard' data-aos="fade-up">

                            <Card.Header className='border-0 d-flex mt-4 justify-content-end okkkk ' as={"h4"}>{item.services}</Card.Header>
                          </Card></Col>

                        <Col lg={7} md={7} sm={12}>
                          <Row>
                            {
                              item.subServices.map((unit, index) => {
                                return (
                                  <>
                                    <Col lg={6} md={6} sm={12}>
                                      <Card className='d-flex justify-content-center align-items-center p-1 m-1 text-center text-white subServiceCard' data-aos="fade-up">
                                        <Card.Header className='border-0' as={"h4"}>{unit}</Card.Header>
                                      </Card>
                                    </Col>

                                  </>
                                )
                              })
                            }
                          </Row>
                        </Col>

                      </>
                  }
                </>
              )
            })
          }
        </Row>
      </Container>
      <Container className=' mt-5'>
        <Row>
          {
            newdata.map((item) => {
              return (
                <>

                  <Col lg={4} md={6} sm={12} className='eng-card text-center d-grid  justify-content-center  '>
                    <img src={item.imgs} alt="" className='mx-auto engs-im' />
                    <p className=' text-black fw-bolder  text-center   '>{item.services}</p>
                  </Col></>
              )
            })
          }
        </Row>
      </Container>





    </>
  )
}

export default Services