import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import aboutImg from "../../Assets/Images/Fire Pump set/1) Diesel Driven Fire Pump.jpg"
import { Animated } from 'react-animated-css'
const AboutComp = () => {
    return (
        <>
            <Container >
                <Row className='justify-content-center my-4'>
                    <Col lg={6}>
                        {/* <Animated animationIn="zoomIn" animationOut="fadeOut" isVisible={true} animationInDuration={2000}> */}
                        <div className='text-center homeAboutImg'>
                            <img src={aboutImg} alt="aboutImg" className={`w-75 mt-3 mb-4 hvr-buzz`} />
                        </div>
                        {/* </Animated> */}
                    </Col>
                    <Col lg={6} className='aboutHome'>
                        {/* <Animated animationIn="fadeInUp" isVisible={true} animationInDuration={2000}> */}
                        <div className='homeAboutImg'>
                            <strong><h3 className='fw-bold'>WELCOME</h3></strong>
                            <h1>ABOUT US</h1>
                            <p className='abouthome_paragraph'>TrikFire Private Limited is a service-providing company specializing in engineering and fire and safety services. We are working in the fields of Diesel Engines for various applications including industrial, genset, and marine applications. We also have a wide range of products for Fire and Safety and provide a complete one-stop solution for Fire and Safety supply and services.</p>
                            {/* <div className='btn-about'>
                                <Button className=' bg-transparent aboutHome_div text-dark border-3'>Read More</Button>
                            </div> */}
                        </div>
                        {/* </Animated> */}
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default AboutComp