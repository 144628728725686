import React from 'react'
import logo from "../Assets/Images/TRIFIRE LOGO orange 1.png"
import { Link, useNavigate } from 'react-router-dom'
import "../index.css"
import { MdLocationOn } from "react-icons/md";
import { ImMobile } from "react-icons/im";
import { HiOutlineMail } from "react-icons/hi";
import { Col, Row } from 'react-bootstrap';
import { IoChevronForwardSharp } from "react-icons/io5";
import { SlArrowRight } from "react-icons/sl";
const Footer = () => {

    return (
        <>
            {/* <!-- Remove the container if you want to extend the Footer to full width. --> */}
            <div className="container-fluid p-0">

                {/* <!-- Footer --> */}
                <footer
                    className="text-center text-lg-start text-white"
                    style={{ backgroundColor: "#1d1d1d" }}
                >
                    {/* <!-- Section: Social media --> */}
                    <section
                        className="d-flex justify-content-between p-3"
                        style={{ backgroundColor: "#ed5d2a" }}
                    >
                    </section>
                    {/* <!-- Section: Social media --> */}

                    {/* <!-- Section: Links  --> */}
                    <section className="">
                        <div className="container-fluid text-center text-md-start mt-5">
                            {/* <!-- Grid row --> */}
                            <div className="row mt-3">
                                {/* <!-- Grid column --> */}
                                <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                                    {/* <!-- Content --> */}
                                    {/* <h6 className="text-uppercase fw-bold">Company name</h6> */}
                                    <div className='text-center'>
                                        <img src={logo} alt="logo" className='mb-3' style={{ width: "35%" }} />
                                    </div>
                                    {/* <hr
                                        className="mb-4 mt-0 d-inline-block mx-auto"
                                        style={{ width: "60px", backgroundColor: "#7c4dff", height: "2px" }}
                                    /> */}
                                    <p style={{ textAlign: "justify" }}>
                                        TrikFire Private Limited is a specialized engineering and fire and safety services company, specializing in Diesel Engines for industrial, generator set, and marine applications, offering comprehensive solution.
                                    </p>
                                </div>
                                {/* <!-- Grid column --> */}

                                {/* <!-- Grid column --> */}
                                <div className="col-md-2 col-lg-2 col-xl-2  mb-4">
                                    {/* <!-- Links --> */}
                                    <h3 className="text-uppercase fw-bold" style={{ color: "#ed5d2a" }}>Links</h3>
                                    <hr
                                        className="mb-4 mt-0 d-inline-block mx-auto"
                                        style={{ width: "60px", backgroundColor: "#7c4dff", height: "2px" }}
                                    />
                                    <p>
                                        <SlArrowRight className="d-none d-md-inline" />&nbsp;<Link to="/" className="text-white footerLinks"> Home</Link>
                                    </p>
                                    <p>
                                        <SlArrowRight className="d-none d-md-inline" />&nbsp;  <Link to="/about" className="text-white footerLinks"> About</Link>
                                    </p>

                                    <p>
                                        <SlArrowRight className="d-none d-md-inline" />&nbsp;<Link to="/services" className="text-white footerLinks"> Services</Link>
                                    </p>
                                    <p>
                                        <SlArrowRight className="d-none d-md-inline" />&nbsp;<Link to="/contact" className="text-white footerLinks"> Contact</Link>
                                    </p>
                                </div>
                                {/* <!-- Grid column --> */}
                                <div className="col-md-3 col-lg-3 col-xl-3  mb-4">
                                    {/* <!-- Links --> */}
                                    <h3 className="text-uppercase fw-bold" style={{ color: "#ed5d2a" }}>Products</h3>
                                    <hr
                                        className="mb-4 mt-0 d-inline-block mx-auto"
                                        style={{ width: "60px", backgroundColor: "#7c4dff", height: "2px" }}
                                    />
                                    <p>
                                        <SlArrowRight className="d-none d-md-inline" />&nbsp;<Link to="/john-deere/diesel-engine/marine" className="text-white footerLinks">Marine</Link>
                                    </p>
                                    <p>
                                        <SlArrowRight className="d-none d-md-inline" />&nbsp;<Link to="/john-deere/diesel-engine/industrial" className="text-white footerLinks">Industrial</Link>
                                    </p>
                                    <p>
                                        <SlArrowRight className="d-none d-md-inline" />&nbsp;<Link to="/john-deere/diesel-engine/generator-set" className="text-white footerLinks">Generator Set</Link>
                                    </p>
                                    <p>
                                        <SlArrowRight className="d-none d-md-inline" />&nbsp;<Link to="/products/fire-pump-set" className="text-white footerLinks">Fire Pump Set</Link>
                                    </p>
                                    <p>
                                        <SlArrowRight className="d-none d-md-inline" />&nbsp;<Link to="/products/fire-fighting-accessories" className="text-white footerLinks">Fire Fighting Accessories</Link>
                                    </p>
                                </div>
                                {/* <!-- Grid column --> */}

                                {/* <!-- Grid column --> */}

                                {/* <!-- Grid column --> */}

                                {/* <!-- Grid column --> */}
                                <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                                    {/* <!-- Links --> */}
                                    <h3 className="text-uppercase fw-bold" style={{ color: "#ed5d2a" }}>Contact</h3>
                                    <hr
                                        className="mb-4 mt-0 d-inline-block mx-auto"
                                        style={{ width: "60px", backgroundColor: "#7c4dff", height: "2px" }}
                                    />

                                    {/* <p><MdLocationOn /> <a className='text-white' target="_blank" href="https://maps.app.goo.gl/wWukSwFEtH9PgXwx5">Plot No.49 Prathmesh, Khutwad Nagar, Nashik, Maharashtra, India</a></p>
                                    <p><ImMobile /> <a href="mailto:amar@archenterprises.co.in" className='text-white'>amar@archenterprises.co.in</a></p>
                                    <p><HiOutlineMail /> <a href="tel:+918390704453" className='text-white'>+91 8390704453</a></p> */}
                                    <Row >
                                        <Col lg={1} md={1} sm={2} xs={1} className='p-1'>
                                            <MdLocationOn style={{ color: '#fd7a01', fontSize: "1.2rem" }} />
                                        </Col>
                                        <Col lg={11} md={11} sm={10} xs={11} className='p-1 mb-2'>
                                            {/* <Card.Text className='text-black'> */}
                                            <a className='text-white' style={{ color: 'white', fontSize: "1.2rem" }} target="_blank" href="https://maps.app.goo.gl/wWukSwFEtH9PgXwx5">Plot No.49 Prathmesh, Khutwad Nagar, Nashik, Maharashtra, India</a>
                                            {/* </Card.Text> */}
                                        </Col>
                                        <Col lg={1} md={1} sm={2} xs={1} className='p-1 mb-2 d-none d-sm-block'>
                                            <ImMobile style={{ color: '#fd7a01', fontSize: "1.2rem" }} />
                                        </Col>
                                        <Col lg={11} md={11} sm={10} xs={12} className='p-1 mb-2'>
                                            {/* <Card.Text className='text-dark'> */}
                                            <ImMobile style={{ color: '#fd7a01', fontSize: "1.2rem" }} className='d-md-none' />
                                            <a href="tel:+918390704453" style={{ color: 'white', fontSize: "1.2rem" }}>+91 8390704453 /</a>
                                            <a href="tel:+918956155816" style={{ color: 'white', fontSize: "1.2rem" }}> 8956155816</a>
                                            {/* </Card.Text> */}
                                        </Col>
                                        <Col lg={1} md={1} sm={2} xs={1} className='p-1 mb-2 d-none d-sm-block'>
                                            <HiOutlineMail style={{ color: '#fd7a01', fontSize: "1.2rem" }} />
                                        </Col>
                                        <Col lg={11} md={11} sm={10} xs={12} className='p-1'>
                                        <HiOutlineMail style={{ color: '#fd7a01', fontSize: "1.2rem" }} className='d-md-none'/>  <a href="mailto:contact@trikfire.com" style={{ color: 'white', fontSize: "1.2rem" }}>contact@trikfire.com</a>
                                        </Col>
                                        <Col lg={1} md={1} sm={2} xs={1} className='p-1 mb-2 d-none d-sm-block'>
                                            <HiOutlineMail style={{ color: '#fd7a01', fontSize: "1.2rem" }} />
                                        </Col>
                                        <Col lg={11} md={11} sm={10} xs={12} className='p-1'>
                                        <HiOutlineMail style={{ color: '#fd7a01', fontSize: "1.2rem" }} className='d-md-none'/> <a href="mailto:sales@trikfire.com" style={{ color: 'white', fontSize: "1.2rem" }}>sales@trikfire.com</a>
                                        </Col>
                                        <Col lg={1} md={1} sm={2} xs={1} className='p-1 mb-2 d-none d-sm-block'>
                                            <HiOutlineMail style={{ color: '#fd7a01', fontSize: "1.2rem" }} />
                                        </Col>
                                        <Col lg={11} md={11} sm={10} xs={12} className='p-1'>
                                        <HiOutlineMail style={{ color: '#fd7a01', fontSize: "1.2rem" }} className='d-md-none'/> <a href="mailto:prasad@trikfire.com" style={{ color: 'white', fontSize: "1.2rem" }}>prasad@trikfire.com</a>
                                        </Col>
                                    </Row>
                                </div>
                                {/* <!-- Grid column --> */}
                            </div>
                            {/* <!-- Grid row --> */}
                        </div>
                    </section>
                    {/* <!-- Section: Links  --> */}

                    {/* <!-- Copyright --> */}
                    <div
                        className="text-center text-dark bg-light p-2"
                        style={{ backgroundColor: "#d9d9d9" }}
                    >
                        <strong> © 2023 Copyright : </strong>
                        <Link className="text-dark" to="https://trikfire.com/"
                        > <strong> Made with Passion by <span onClick={() => window.open('https://www.sumagoinfotech.com', '_blank')} style={{ color: "#ed5d2a" }}>Sumago Infotech </span>
                            </strong> </Link>
                    </div>
                    {/* <!-- Copyright --> */}
                </footer>
                {/* <!-- Footer --> */}

            </div>
            {/* <!-- End of .container --> */}
        </>
    )
}

export default Footer