import React from 'react'
import Banner from '../Components/Banner'
import banner from "../Assets/Images/product_banner.jpg"
import bannerMob from "../Assets/Images/FIRE FIGHTING.jpg"
import Heading from '../Components/Heading'
import ProductCard from '../Components/CardsComponents/ProductCard'
import img1 from "../Assets/Images/Fire Fighting Accessories/vavles/a) Butterfly valve.jpg"
import img2 from "../Assets/Images/Fire Fighting Accessories/vavles/b) Gate Valves.jpg"
import img3 from "../Assets/Images/Fire Fighting Accessories/vavles/c)Deluge Valve.jpg"
import img4 from "../Assets/Images/Fire Fighting Accessories/vavles/E) prv pressure reducing & regulating vavles.jpg"
import img5 from "../Assets/Images/Fire Fighting Accessories/vavles/f ) strainer 2.jpg"
import img6 from "../Assets/Images/Fire Fighting Accessories/vavles/f ) strainer.jpg"
import img7 from "../Assets/Images/Fire Fighting Accessories/vavles/g) Bladder Tank Set.jpg"
import img8 from "../Assets/Images/Fire Fighting Accessories/vavles/h) Sprinkler.jpg"
import img9 from "../Assets/Images/Fire Fighting Accessories/vavles/i) Extinguishers.jpg"
import img10 from "../Assets/Images/Fire Fighting Accessories/vavles/j)Hydrant and valves.jpg"
import img11 from "../Assets/Images/kfs.webp"
const FFA = () => {
  window.scrollTo(0, 0);
  return (
    <>
      {
        window.innerWidth > "576" ?
          <Banner banner={banner} />
          :
          <Banner banner={bannerMob} />
      }
      <Heading Headings={"FIRE FIGHTING ACCESSORIES"} className={"text-center"} />
      <ProductCard data={data} ProductClass={"ProductCard"} />
    </>
  )
}

export default FFA

const data = [
  {
    imgs: img1,
    title: "Butterfly valve",
    Model: "409"
  },
  {
    imgs: img2,
    title: "Gate Valve",
    Model: "420"
  },
  {
    imgs: img3,
    title: "Deluge Valve",
    Model: "409"
  },
  {
    imgs: img4,
    title: "Regulating vavles",
    Model: "420"
  },
  {
    imgs: img5,
    title: "Sprinkler",
    Model: "409"
  },
  {
    imgs: img6,
    title: "Strainer",
    Model: "420"
  },
  {
    imgs: img7,
    title: "Bladder Tank Set",
    Model: "409"
  },
  {
    imgs: img9,
    title: "Extinguishers",
    Model: "409"
  },
  {
    imgs: img10,
    title: "Hydrant and valves",
    Model: "420"
  },
  {
    imgs: img11,
    title: "Kitchen Separation System",
    Model: "420"
  },
]