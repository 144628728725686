import React from 'react'
import trikFireLogo from '../../Assets/Images/Group 21.png'
import JohnDeereLogo from '../../Assets/Images/Group 41.png'
import { Button, Col, Container, Row } from 'react-bootstrap'
import "../../index.css"
import "../../App.css"
const Section1 = () => {
  return (
    <>
      <Container fluid>
        <Row className='my-4 px-2 py-5 aboutSection1'>
          <Col lg={6} className='d-flex justify-content-center'>
            <img src={trikFireLogo} alt="trikFireLogo" className='mb-3' />
          </Col>
          <Col lg={6} className='d-flex align-items-center justify-content-center flex-column'>

            {
              window.innerWidth > 576 &&
              <h1 className='text-white text-normal'>ABOUT US</h1>
            }

            <p className="about_p">
              TrikFire Private Limited is a service-providing company specializing in engineering and fire and safety services. We are working in the fields of Diesel Engines for various applications including industrial, genset, and marine applications. We also have a wide range of products for Fire and Safety and provide a complete one-stop solution for Fire and Safety supply and services.
            </p>
            {/* <div className='btn-about'>
              <Button variant="outline-light p-2 rounded-4 fs-5 border-3">Read More</Button>
            </div> */}
          </Col>
        </Row>
        <Row className='my-4 px-2 py-5 aboutSection2'>
          <Col lg={6} className='d-flex justify-content-center d-block d-md-none'>
            <img src={JohnDeereLogo} alt="JohnDeereLogo" className='mb-3' />
          </Col>
          <Col lg={6} className='d-flex align-items-center justify-content-center flex-column'>
            {

              window.innerWidth > 576 &&
              <h1 className='text-white'>John Deere</h1>
            }
            <p className="about_p">
              John Deere is the brand name of Deere & Company, the world’s leading manufacturer of agricultural, forestry machinery, drivetrains, construction, and diesel engines. In 2018, it was recorded as 102nd in the Fortune 500 America's ranking and was ranked 394th in the global ranking. It has a long history of designing and manufacturing quality products around the world. It has been making sustainable development possible and driving positive change on all zone. Deere & Company is listed on the New York Stock Exchange under the symbol DE. The slogan of  the company is "Nothing Runs like a Deere", and a leaping deer is its logo with the words 'JOHN DEERE' under it. At John Deere, you will get the worth of choice for your replacement and maintenance parts for all types and ages of machines at any budget.
            </p>
            {/* <div className='btn-about'>
              <Button variant="outline-light p-2 rounded-4 fs-5 border-3">Read More</Button>
            </div> */}
          </Col>
          {
            window.innerWidth > 576 &&
            <Col lg={6} className='d-flex justify-content-center'>
              <img src={JohnDeereLogo} alt="JohnDeereLogo" />
            </Col>
          }
        </Row>

      </Container>
    </>
  )
}

export default Section1