import React from 'react'
import Banner from '../Components/Banner'
import banner from "../Assets/Images/new_marin.jpg"
import bannerMob from "../Assets/Images/marineMob.jpg"
import Heading from '../Components/Heading'
import ProductCard from '../Components/CardsComponents/ProductCard'
import img1 from "../Assets/Images/diesel engine/marine/image034.jpg"
import img2 from "../Assets/Images/diesel engine/marine/image035.jpg"
import img3 from "../Assets/Images/diesel engine/marine/image036.jpg"
import marineImg from "../Assets/Images/marine_img.jpg"
import "../index.css"
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
// const MarineSet = () => {
//     window.scrollTo(0, 0);
//     return (
//         <>
//             {
//                 window.innerWidth > "576" ?
//                     <Banner banner={banner} />
//                     :
//                     <Banner banner={bannerMob} />
//             }
//             <Heading Headings={"MARINE SET"} className={"text-center"} />
//             <ProductCard data={data} ProductClass={"dieselProduct"} />
//         </>
//     )
// }
const MarineSet = () => {
    window.scrollTo(0, 0);
    return (
        <>
            <div className='p-md-4 py-4 px-2' style={{ background: "#eff0f0" }}>
                <Heading Headings={"Marine Diesel Engines"} className={"mx-md-5"} />
            </div>
            <Container fluid>
                <Row style={{ height: "75%" }}>
                    {
                        window.innerWidth < 576 &&
                        <Col lg={7} md={7} sm={6} className='p-0'>
                            <img src={marineImg} className='img-fluid' style={{ height: window.innerWidth < 576 ? "200px" : "" }} alt="marineImg" />
                        </Col>
                    }
                    <Col lg={5} md={5} sm={6} className='marineSection d-flex flex-column align-items-center justify-content-center'>
                        <div className='mx-md-5 my-3'>
                            <h2 className='text-white'>
                                Marine Propulsion, Generator, and Auxiliary Engines
                            </h2>
                            <p className=''>
                                Find the John Deere marine engine with the pure power and rugged reliability you need.
                            </p>
                            <Button className='p-2 ' variant="outline-warning"> <a className='text-white' href="https://www.deere.com/en/marine-engines/" target='_blank'> See Our Full Lineup </a></Button>
                        </div>
                    </Col>
                    {
                        window.innerWidth > 576 &&
                        <Col lg={7} md={7} sm={6} className='p-0'>
                            <img src={marineImg} className='img-fluid' style={{ height: "390px" }} alt="marineImg" />
                        </Col>
                    }
                </Row>
            </Container >

        </>
    )
}

export default MarineSet

const data = [
    {
        imgs: img1,
        title: "MARINE",
        Model: "409"
    },
    {
        imgs: img2,
        title: "MARINE",
        Model: "420"
    },
    {
        imgs: img3,
        title: "MARINE",
        Model: "409"
    },
]