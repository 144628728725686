import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import Section1 from '../PageComponents/AboutComp/Section1'
import Section2 from '../PageComponents/AboutComp/Section2'

const About = () => {
  window.scrollTo(0, 0);
  return (
    <>
      <Section1 />
      <Section2 />
    </>
  )
}

export default About