import React from 'react';
import { Col, Container, Row, Card, Button } from 'react-bootstrap';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import Heading from '../Heading';

function CardComponent1({ Headings, data }) {
    const swiperParams = {
        spaceBetween: 30,
        slidesPerView: 3,
        navigation: {
            nextEl: '.swiper-button-next', // Add appropriate class or selector
            prevEl: '.swiper-button-prev', // Add appropriate class or selector
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        onSwiper: (swiper) => console.log(swiper),
        breakpoints: {
            0: {
                slidesPerView: 1, // Show one card for mobile devices (width < 576px)
            },
            576: {
                slidesPerView: 2, // Show two cards for tablets (width >= 576px)
            },
            992: {
                slidesPerView: 3, // Show three cards for larger screens (width >= 992px)
            },
        },
    };
    return (
        <>
            <Container fluid>
                {
                    Headings &&
                    <Heading Headings={Headings} />
                }
                <Row>
                    <Swiper key="swiper1"
                        {...swiperParams}
                    >
                        {
                            data?.map((item, id) => {
                                return (
                                    <>
                                        <Col key={id} lg={4} sm={6} xs={6}>
                                            <SwiperSlide>
                                                <Card className='my-2 card1' >
                                                        <Card.Img data-aos="zoom-in" className='w-75 bg-light my-3 mx-auto d-block' variant="top" src={item?.images} />
                                                  
                                                    <Card.Body className='text-center'>
                                                        <Card.Title>{item?.title}</Card.Title>
                                                        {/* <Card.Text>
                                                            {item?.description}
                                                            <strong className='text-black'>{item.model}</strong>
                                                        </Card.Text> */}
                                                        {/* <div className='btnStyle'>
                                                            <Button variant="outline-primary bg-transparent border-3 aboutHome_div text-center text-dark">{item?.buttonName}</Button>
                                                        </div> */}
                                                    </Card.Body>
                                                </Card>
                                            </SwiperSlide>
                                        </Col>
                                    </>
                                )
                            })
                        }
                    </Swiper>
                </Row>
            </Container>
        </>
    );
}
export default CardComponent1;