import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import visionImg from '../../Assets/Images/Group 22.png'
import missionImg from '../../Assets/Images/Group 23.png'
let VisionMissionCard = [
    {
        headerName: "VISION",
        description: "To emerge as a pioneering force in fire safety and industrial excellence, Trikfire Engineering Private Limited envisions a future where safety and productivity seamlessly coexist. We strive to be the trusted provider of advanced fire suppression solutions and the preferred partner for John Deere industrial machinery, contributing to the prosperity and safety of industries across India. Feel free to customize these statements to better align with the specific values and objectives of Trikfire Engineering Private Limited.",
        imgs: visionImg
    },
    {
        headerName: "MISSION",
        description: "At Trikfire Engineering Private Limited, our mission is twofold. Firstly, we are committed to delivering unparalleled quality in fire suppression systems, offering comprehensive sets and accessories that ensure optimal safety and protection for industries. Secondly, as a reputable John Deere industrial machinery dealer in India, we aim to empower businesses with state of the art equipment, fostering efficiency, and innovation. Rooted in integrity and client satisfaction, our mission is to elevate safety standards and industrial capabilities, thereby playing a pivotal role in the growth and success of our clients.",
        msg:"Feel free to customize these statements to better align with the specific values and objectives of Trikfire Engineering Private Limited.",
        imgs: missionImg
    }
]
const Section2 = () => {
    return (
        <>
            <Container fluid>
                <Row className='visionMission my-3'>
                    {
                        VisionMissionCard.map((item, id) => {
                            return (
                                <Col lg={6}>
                                    <Card className='mb-5 bg-light' style={{height:"95%"}}>
                                        <Card.Header className='p-3 px-4' as="h1"><Card.Img style={{width:"15%"}} variant="top" src={item.imgs} />&nbsp;{item.headerName}</Card.Header>
                                        <Card.Body className='px-3'>
                                            {/* <Card.Title>Special title treatment</Card.Title> */}
                                            <Card.Text >
                                                {item.description}<br/>
                                            {
                                                item.msg &&
                                                item.msg
                                            }
                                            </Card.Text>
                                            {/* <div className='text-center pt-3'>
                                                <Card.Img className={`${id == 0 ? "visionMissionImg" :""} d-none d-md-block`} variant="top" src={item.imgs} />
                                            </div> */}
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        })
                    }

                </Row>
            </Container>
        </>
    )
}

export default Section2